(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/widget-area/assets/widget-area.js') >= 0) return;  svs.modules.push('/components/lb-utils/widget-area/assets/widget-area.js');
"use strict";

const _excluded = ["widgetArea"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

const {
  useEffect,
  useState
} = React;
const {
  loadArea
} = svs.core.widget_area;
const WidgetArea = _ref => {
  let {
      widgetArea
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const [content, setContent] = useState('');
  useEffect(() => {
    let isMounted = true;
    loadArea(widgetArea, content => {
      if (isMounted) {
        setContent(content);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [widgetArea]);
  return (
    React.createElement("div", _extends({
      dangerouslySetInnerHTML: {
        __html: content
      }
    }, props))
  );
};
setGlobal('svs.components.lbUtils.widgetArea.WidgetArea', WidgetArea);

 })(window);